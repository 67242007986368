import React from "react";
import ReactIframeResizer from 'react-iframe-resizer-super';
// See config options on:
// * https://www.npmjs.com/package/react-iframe-resizer-super
// * https://www.npmjs.com/package/iframe-resizer

import Layout from "src/components/layout";
import Hero from "src/components/hero";
import Breadcrumb from "src/components/breadcrumb";
import SearchSubpage from "src/components/search-subpage";
// import SubNav from "src/components/subnav";
import Card from "src/components/card";

// images
import logoUnion from "src/images/temp/logo-ilw-u13.png";
import logoIbew from  "src/images/temp/logo-ibew-local-11.png";


class Components extends React.Component {
  render() {
    const pageData = {
      categories: [ {name: "Category", slug: "environment"}],
      title: 'subpage',
      acf: {
        heading_line1: 'Subpage Components',
        subheading: 'Various components used by subpages.'
      }
    }
    // 'https://source.unsplash.com/1920x443'

    const iframeResizerOptions = {
      minHeight: 200,
      scrolling: true,
      tolerance: 30
    }

    return (
      <Layout hasHero={true}>
        <Hero type="short" headingLine1={pageData.acf.heading_line1} headingLine2={pageData.acf.heading_line2} heroBackgroundUrl={pageData.acf.hero_background} subheading={pageData.acf.subheading} />

        <Breadcrumb categories={pageData.categories} pageTitle={pageData.title} />
        
        <div className="grid grid--subpage gutter">
          <aside className="sidebar">
            <h2 className="sidebar__heading">Explore Resources</h2>
            <SearchSubpage />
            {/* <SubNav /> */}
          </aside>
          
          <article className="content-wrap">

            {/* ==== START Component: Executives ==== */}
            <div className="content">
              <hr />
              <h2>Component: Executives</h2>
            </div>

            <div className="content">
              {/* A copy-paste from WP */}
              <div className="wp-block-polb-executives executive">
                <img className="executive__img" alt="" src="https://thehelm.polb.com/wp-content/uploads/temp-photo-executive.png" />
                <div className="executive__description">
                  <h4 className="executive__title">Executive Director</h4>
                  <strong>Mario Cordero</strong> | (562) 283-7080
                  <br />The Executive Director leads the Port’s Harbor Department staff of more than 500 people with an annual budget of nearly $800 million.
                  <br /><br />
                  <strong>Executive Assistant-Executive Director</strong>
                  <br />
                  Stacey Rebaza | (562) 283-7097
                </div>
              </div>

              <div className="wp-block-polb-executives executive">
                <img className="executive__img" alt="" src="https://thehelm.polb.com/wp-content/uploads/temp-photo-executive.png" />
                <div className="executive__description">
                  <h4 className="executive__title">Executive Director</h4>
                  <strong>Mario Cordero</strong> | (562) 283-7080
                  <br />The Executive Director leads the Port’s Harbor Department staff of more than 500 people with an annual budget of nearly $800 million.
                  <br /><br />
                  <strong>Executive Assistant-Executive Director</strong>
                  <br />
                  Stacey Rebaza | (562) 283-7097
                </div>
              </div>
            </div>

            {/* ==== START Component: More Hiring Resources cards ==== */}
            <div className="content">
              <hr />
              <h2>Component: More Hiring Resources cards</h2>
            </div>

            <div className="content">
              <p>A list of resources for local opps.</p>

              <ul className="color-columns">
                <li>
                  <img src={logoUnion} alt="logo" />
                  <h4>Hire Long Beach</h4>
                  <p>Pacific Gateway Workforce Innovation Network connects adults, youth, and businesses to opportunities—job seekers to employment, and businesses to skilled workers.</p>
                  <p><strong>Phone:</strong> (562) 570-3700<br />
                  <strong>TTY:</strong> (562) 570-4629<br />
                  https://www.pacific-gateway.org/</p>
                </li>
                <li>
                  <img src={logoUnion} alt="logo" />
                  <h4>International Longshore & Warehouse Union Local 13</h4>
                  <p>Local dock workers union, represents most employees at terminals.</p>
                  <p><strong>Phone:</strong> (310) 830-1130</p>
                </li>
                <li>
                  <img src={logoIbew} alt="logo" />
                  <h4>International Brotherhood of Electrical Workers, IBEW Local 11</h4>
                  <p>Jane Templin, Outreach Director</p>
                  <p><strong>Phone:</strong> (323) 221-5881 ext. 3004</p>
                </li>
                <li>
                  <h4>Pacific Maritime Association</h4>
                  <p>Represents shippers, negotiates contracts with ILWU.</p>
                  <p><strong>Phone:</strong> (562) 495-7600</p>
                </li>
              </ul>
            </div>

            {/* ==== START Visualizer iframe (with ReactIframeResizer) ==== */}
            <div className="content">
              <hr />
              <h2>Visualizer iframe with Gravity Form</h2>
              <ReactIframeResizer 
                iframeResizerOptions={iframeResizerOptions} 
                iframeResizerUrl={false} 
                className="gravity-test">
<div className='gf_browser_unknown gform_wrapper' id='gform_wrapper_1'>
    <form method='post' enctype='multipart/form-data' id='gform_1' action='https://thehelm.polb.com/wp-json/wp/v2/pages/933'>
        <div className='gform_heading'>
            <h3 className='gform_title'>Test</h3> <span className='gform_description'></span> </div>
        <div className='gform_body'>
            <ul id='gform_fields_1' className='gform_fields top_label form_sublabel_below description_below'>
                <li id='field_1_3' className='gfield gfield_contains_required field_sublabel_below field_description_below gfield_visibility_visible'><label className='gfield_label' htmlFor='input_1_3'>NAME<span className='gfield_required'>*</span></label>
                    <div className='ginput_container ginput_container_text'><input name='input_3' id='input_1_3' type='text' defaultValue='' className='medium' aria-required="true" aria-invalid="false" /></div>
                </li>
                <li id='field_1_4' className='gfield gfield_contains_required field_sublabel_below field_description_below gfield_visibility_visible'><label className='gfield_label' htmlFor='input_1_4'>EMAIL ADDRESS<span className='gfield_required'>*</span></label>
                    <div className='ginput_container ginput_container_text'><input name='input_4' id='input_1_4' type='text' defaultValue='' className='medium' aria-required="true" aria-invalid="false" /></div>
                </li>
                <li id='field_1_5' className='gfield gfield_contains_required field_sublabel_below field_description_below gfield_visibility_visible'><label className='gfield_label' htmlFor='input_1_5'>MESSAGE<span className='gfield_required'>*</span></label>
                    <div className='ginput_container ginput_container_textarea'><textarea name='input_5' id='input_1_5' className='textarea medium' aria-required="true" aria-invalid="false" rows='10' cols='50'></textarea></div>
                </li>
            </ul>
        </div>
        <div className='gform_footer top_label'> <input type='submit' id='gform_submit_button_1' className='gform_button button' value='Submit' /> <input type='hidden' className='gform_hidden' name='is_submit_1' value='1' /> <input type='hidden' className='gform_hidden' name='gform_submit' value='1' /> <input type='hidden' className='gform_hidden' name='gform_unique_id' defaultValue='' /> <input type='hidden' className='gform_hidden' name='state_1' value='WyJbXSIsIjMxODZkMjMxZDI4ZTA3OThjOThjNzJkNzMwODg0Nzk4Il0=' /> <input type='hidden' className='gform_hidden' name='gform_target_page_number_1' id='gform_target_page_number_1' value='0' /> <input type='hidden' className='gform_hidden' name='gform_source_page_number_1' id='gform_source_page_number_1' value='1' /> <input type='hidden' name='gform_field_values' value='' /> </div>
    </form>
</div>

                </ReactIframeResizer>
            </div>


            {/* ==== START Visualizer iframe (with ReactIframeResizer) ==== */}
            <div className="content">
              <hr />
              <h2>Visualizer iframe (with component and resizing)</h2>

              <div className="table-wrapper"></div>
              <ReactIframeResizer 
                src="https://thehelm.polb.com/visualizer/?chart=2517" 
                iframeResizerOptions={iframeResizerOptions} 
                iframeResizerUrl={false} 
                className="visualizer"></ReactIframeResizer>
              
            </div>

            {/* ==== START Visualizer iframe (hardcoded) ==== */}
            <div className="content">
              <hr />
              <h2>Visualizer iframe (hardcoded)</h2>
              {/* <iframe src="https://thehelm.polb.com/visualizer/?chart=2517" className="wp--iframe"></iframe>*/}
            </div>


            {/* ==== START Component: Files table ==== */}
            <div className="content">
              <hr />
              <h2>Component: Files table</h2>
            </div>

            <div className="table-wrapper">
            <table className="files">
              <caption className="visuallyhidden">Files you may download</caption>
              <thead>
                <tr>
                  <th scope="col">Name <button type="button" className="btn-sort">sort</button></th>
                  <th scope="col">Size</th>
                  <th scope="col">File</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Sediment Management Plan</td>
                  <td>2MB</td>
                  <td><a href="https://domain.com/the-file.pdf">PDF</a></td>
                </tr>
                <tr>
                  <td>An example PPT doc</td>
                  <td>2MB</td>
                  <td><a href="https://domain.com/another-file.ppt">PPT</a></td>
                </tr>
                <tr>
                  <td>An example Word doc</td>
                  <td>2MB</td>
                  <td><a href="https://domain.com/another-file.docx">DOC</a></td>
                </tr>
              </tbody>
            </table>
            </div>


            {/* ==== START Component: Card ==== */}
            <div className="content">
              <hr />
              <h2>Component: Card (max 3 square cols)</h2>
              <p>Used on Green Port Policy. [<a href="https://projects.invisionapp.com/share/9HQYQQ884VW#/screens/353731564" rel="noopener noreferrer" target="_blank"><strong>Design</strong></a>]
              </p>
              <Card cardType="square-3" />
            </div>
          </article>
        </div>
      </Layout>
    );
  }
}

export default Components;