import React from "react";
import { navigate } from "gatsby";

const fetch = require('isomorphic-fetch')

/**
 * Form to search subpages (appears in sidebar above subnav)
 */
class SearchSubpage extends React.Component {
  constructor(props) {
    super(props);
    this.searchSubpageInput = React.createRef();
    this.handleSearchSubpage = this.handleSearchSubpage.bind(this);
  }

  state = {
    errorMessage: "",
    searchResults: [],
    searchValue: ""
  }


  // Event handler for when subpage search form submitted
  handleSearchSubpage(e) {
    e.preventDefault();
    const value = this.searchSubpageInput.current.value;

    fetch(`https://thehelm.polb.com/wp-json/relevanssi/v1/search?s=${value}`)
      .then(response => response.json())
      .then(json => {
        this.setState({ 
          searchResults: json.results,
          searchValue: value 
        });
        navigate("/documents/search-results", { state: { searchResults: this.state.searchResults, searchValue: this.state.searchValue } });
      })
  }



  

  render() {

    return (
      <section>
        <form className="form-search-subpage" onSubmit={this.handleSearchSubpage}>
          <label htmlFor="search-subpage" className="visuallyhidden">Search this page:</label>
          <input type="search" id="search-subpage" placeholder="Search" className="form-search-subpage__field" ref={this.searchSubpageInput} />
          <button type="submit" className="form-search-subpage__btn" aria-label="submit page search"><svg className="icon icon--search"><use xlinkHref="#icon-search" /></svg></button>
        </form>
        {this.state.errorMessage && (<p className="error--message intro">{this.state.errorMessage}</p>)}
      </section>
    )
  }
}

export default SearchSubpage;

